import './App.css';

// import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
// import { FaRegQuestionCircle } from "react-icons/fa";

function App() {
  return (
    <div id='app'>
      <div id='heroContainer'>
        <navbar>
          <h1 className='primaryColor'>TRUST</h1>
          {/* <div>
            <a href='/'>About</a>
            <a href='/'>Services</a>
            <a href='/'>Contact</a>
          </div> */}
        </navbar>
        <div className='hrContainer'>
          <hr />
        </div>
        <div id='heroCenteredContainer'>
          <img src='https://firebasestorage.googleapis.com/v0/b/spranger-ventures.appspot.com/o/Trust%2FTrustLogoTemp.png?alt=media&token=655e87a2-f098-4522-908f-93f51373a67b' alt='logo' />
          <h2>
            Linking global
          </h2>
          <p>
            Achieve seamless transitions and global growth
          </p>
        </div>
      </div>
      {/* <div id='servicesContainer' className='section'>
        <h2>
          Services
        </h2>
        <p>
          We provide tailored solutions across various sectors. Our services
          are designed to support businesses and individuals in navigating
          global operations and transitions with ease.
        </p>
        <div>
          <div className='serviceContainer'>
            <div className='serviceIconContainer'>
              <FaRegQuestionCircle />
            </div>
            <div>
              <h3>
                People
              </h3>
              <p>
                Helping businesses build and manage exceptional teams
                through tailored recruitment services and talent acquisition
                strategies.
              </p>
            </div>
          </div>
          <div className='serviceContainer'>
            <div className='serviceIconContainer'>
              <FaRegQuestionCircle />
            </div>
            <div>
              <h3>
                Real estate
              </h3>
              <p>
                Offering expert guidance and comprehensive property
                solutions for both individuals and businesses.
              </p>
            </div>
          </div>
          <div className='serviceContainer'>
            <div className='serviceIconContainer'>
              <FaRegQuestionCircle />
            </div>
            <div>
              <h3>
                Relocation
              </h3>
              <p>
                Assisting expatriates and newcomers in their transition to El
                Salvador with personalized support for housing, settling in
                and others.
              </p>
            </div>
          </div>
          <div className='serviceContainer'>
            <div className='serviceIconContainer'>
              <FaRegQuestionCircle />
            </div>
            <div>
              <h3>
                Solutions
              </h3>
              <p>
                Providing robust testing services to ensure you hire the right
                talent for your needs.
              </p>
            </div>
          </div>
          <div className='serviceContainer' style={{marginBottom: 0}}>
            <div className='serviceIconContainer'>
              <FaRegQuestionCircle />
            </div>
            <div>
              <h3>
                Consulting
              </h3>
              <p>
                Empowering your team with specialized training, coaching,
                and technical guidance to foster growth and innovation.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div id='aboutUsContainer' className='section'>
        <h2>
          About us
        </h2>
        <p>
          We’re a dedicated team of professionals passionate about
          creating global opportunities and building bridges between
          talent, businesses, and communities. From assisting
          companies in finding top-tier candidates to supporting
          individuals navigating new beginnings in El Salvador, our
          focus is on making the complex simple and the impossible
          achievable.
        </p>
      </div> */}
      {/* <div id='contactContainer' className='section'>
        <h2>
          Contact
        </h2>
        <p>
          Donec imperdiet eros a nibh mattis, eu commodo odio porttitor. Morbi pretium nibh vel convallis.
        </p>
        <div id='contactForm'>
          <input type='text' placeholder='Name' />
          <input type='email' placeholder='Email' />
          <textarea placeholder='Message'></textarea>
          <button>
            Send
          </button>
        </div>
      </div> */}
      <div className='hrContainer' style={{marginTop: '1rem'}}>
        <hr />
      </div>
      <footer>
        <h1 className='primaryColor' style={{marginBottom: '2rem'}}>
          TRUST
        </h1>
        {/* <div id='footerLinks'>
          <a href='/' className='footerLink'>About</a>
          <a href='/' className='footerLink'>Services</a>
          <a href='/' className='footerLink'>Contact</a>
        </div> */}
        {/* <div id='footerSocials'>
          <a href='/' className='footerSocialIcon'>
            <FaFacebookF />
          </a>
          <a href='/' className='footerSocialIcon'>
            <FaInstagram />
          </a>
          <a href='/' className='footerSocialIcon'>
            <FaLinkedinIn />
          </a>
        </div> */}
        <p>
          &copy; 2021 TRUST. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default App;
